// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("jquery")

//= require rails-ujs
// require jquery
//= require tinymce-jquery
//require ("jquery_ujs")
require("@rails/ujs").start()
//require("turbolinks").start()

import "../controllers"
//import Swiper, { Navigation, Pagination, Scrollbar, Thumbs, Mousewheel, EffectCards }  from 'swiper';
import tippy from 'tippy.js';

require("@rails/activestorage").start()

//ЗАГРУЗКА КАРТИНОК
require("packs/jquery.fileuploader")

require("packs/select2")
require("packs/topbox")
require("channels")
require("packs/search")
require("packs/parsley/parsley.min")
require("packs/parsley/i18n/ru")


window.jQuery = window.$ = require('jquery')


$(document).ready(function () {
    $(document).on('change', '.js_cart_change', function () {
        $(this).next().click();
    });

    $('.js_lightbox_link').topbox({
        effect: 'fade',
        skin: 'minimal',
    });

    var FILE_ICON_URL = 'https://icons.iconarchive.com/icons/zhoolego/material/512/Filetype-Docs-icon.png'

    function addFileToNewInput(file, newInput) {
        if (!newInput) {
            return
        }

        var dataTransfer = new DataTransfer()
        dataTransfer.items.add(file)
        newInput.files = dataTransfer.files
    }

    function addSrcToPreview(file, preview) {
        if (!preview) {
            return
        }

        if (file.type.match(/image/)) {
            var reader = new FileReader()
            reader.onload = function (e) {
                preview.src = e.target.result
            }
            reader.readAsDataURL(file)
        } else {
            preview.src = FILE_ICON_URL
        }
    }

    function breakIntoSeparateFiles(input, targetSelector, templateSelector) {
        var $input = $(input),
            templateHtml = $(templateSelector).html();

        if (!input.files) {
            return
        }
        console.log(input.files);
        for (var file of input.files) {
            var $newFile = $(templateHtml).appendTo(targetSelector)
            addSrcToPreview(file, $newFile.find("img")[0])
            //addFileToNewInput(file, $newFile.find("input")[0])
        }

        $('#upload_input').val(input.files)
    }

    window.breakIntoSeparateFiles = breakIntoSeparateFiles;


    //Теги
    $(".tags_dropdown").select2({
        width: "100%",
        language: "ru",
        ajax: {
            url: '/tags/search',
            dataType: 'json'
            // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
        },
        placeholder: "Выберети или добавте тег",
        tags: true
    });

    // Товары
    $(".goods_dropdown").select2({
        width: "100%",
        language: "ru",
        ajax: {
            url: '/stocks/searchstock',
            dataType: 'json'
            // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
        },
        placeholder: "Выберети товар",
        tags: true
    });

    //ВАЛИДАЦИЯ
    $('.js_valid_form').parsley();

    // Бренды
    $(".brans_dropdown").select2({
        width: "100%",
        language: "ru",
        ajax: {
            url: '/brands/searchbrand',
            dataType: 'json'
            // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
        },
        placeholder: "Выберети или добавте бренд",
        tags: true
    });

    // Варианты товара
    $(".goodtags_dropdown").select2({
        width: "100%",
        language: "ru",
        ajax: {
            url: '/goodtag/searchgoodtag',
            dataType: 'json'
            // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
        },
        placeholder: "Выберети или добавте название вариантов товара",
        tags: true
    });

    // Каталог
    $(".catalog_dropdown").select2({
        width: "100%",
        language: "ru",
        placeholder: "Выберети категорию",
        allowClear: true,
    });

    // Каталог
    $(".js_select").select2({
        width: "100%",
        language: "ru",
        placeholder: "-",
        minimumResultsForSearch: -1
    });

    $(function () {
        $('#products th').on('click', 'a', function () {
            $.getScript(this.href);
            return false;
        });
        $('#products .pagination').on('click', 'a', function () {
            $.getScript(this.href);
            return false;
        });
        $("#products_search input").keyup(function () {
            $.get($("#products_search").attr("action"), $("#products_search").serialize(), null, "script");
            return false;
        });
    });

    //Добавить поле - страница тип товара
    (function () {
        $(document).on('click', 'form .remove_fields', function (event) {
            $(this).prev('input[type=hidden]').val('1');
            $(this).closest('fieldset').hide();
            return event.preventDefault();
        });

        $(document).on('click', 'form .add_fields', function (event) {
            var regexp, time;
            time = new Date().getTime();
            regexp = new RegExp($(this).data('id'), 'g');
            $(this).before($(this).data('fields').replace(regexp, time));
            return event.preventDefault();
        });

    }).call(this);

    // #ФИЛЬТРАЦИЯ
    $('.js_hidefilter').click(function () {
        $(this).toggleClass('st_hide');
    });

    //ТАБЫ
    (function ($) {
        jQuery.fn.tabs = function (options) {
            // OPTIONS
            var options = $.extend({
                classes: {
                    links: "b_tabs_link",
                    tabs: "b_tabs_tab",
                    active: "st_active"
                }
            }, options);

            // MAKE
            var make = function () {
                var $this = $(this),
                    $links = $this.find('.' + options.classes.links),
                    $tabs = $this.find('.' + options.classes.tabs),

                    hash = window.location.hash,
                    $start = $links.filter('[href="' + window.location.hash + '"]');


                $links.on('click', function () {

                    var $this = $(this);
                    $links.removeClass(options.classes.active);
                    $this.addClass(options.classes.active);
                    $tabs
                        .removeClass(options.classes.active)
                        .eq($this.index()).addClass(options.classes.active);
                });

                if ($start.length) {
                    $start.click();
                } else {
                    $links.first().click();
                }
            };

            return this.each(make);
        };
    })(jQuery);

    //FILTERS TABS
    $('.js_tabs').tabs({
        classes: {
            links: 'js_tabs_link',
            tabs: 'js_tabs_tab',
            active: 'st_active'
        }
    });


    //ТИП ДОСТАВКИ ПЕРЕКЛЮЧЕНИЕ
    $('.js-tab-trigger').click(function () {
        var id = $(this).attr('data-tab'),
            content = $('.js-tab-content[data-tab="' + id + '"]');
        if (id == '1') {
            $('#suggest').prop('required', true);
        } else {
            $('#suggest').prop('required', false);
        }
        $('.js-tab-content.d-active').removeClass('d-active');
        $('.js-tab-trigger.active').removeClass('active');
        $(this).addClass('active');
        content.addClass('d-active');
    });
    //ТИП САМОВЫВОЗА
    $('.js-pickup-trigger').click(function () {
        var id = $(this).attr('data-pickup'),
            content = $('.js-pickup-content[data-pickup="' + id + '"]');
        if (id == '1') {
            $('#suggest').prop('required', true);
        } else {
            $('#suggest').prop('required', false);
        }
        $('.js-pickup-content.d-active').removeClass('d-active');
        content.addClass('d-active');
    });

    $(function () {
        var $window = $(window);
        var $sidebar = $(".order-mobile-fixed-menu");
        if ($sidebar.length) {
            var $sidebarTop = $sidebar.position().top;
        }
        var $sidebarHeight = $sidebar.height();
        var $footer = $('.js-footer');
        var $footerTop = $footer.position().top;

        $window.scroll(function (event) {
            $sidebar.addClass("fixed");
            var $scrollTop = $window.scrollTop();
            var $topPosition = $scrollTop + $sidebarTop + $sidebarHeight + 50;
            var $topFooter = $('.js-footer').height();

            if ($topPosition > $scrollTop) {
                $sidebar.removeClass("fixed");
            } else {
                $sidebar.addClass("fixed");
            }

            if(($(window).scrollTop() + $topFooter) > ($('footer').offset().top)){
                $('.to-top').addClass('invert');
              } else {
                $('.to-top').removeClass('invert');
              }

        });
    });


    //МАКСА ТЕЛЕФОНА
    function setCursorPosition(pos, elem) {
        elem.focus();
        if (elem.setSelectionRange) elem.setSelectionRange(pos, pos);
        else if (elem.createTextRange) {
            let range = elem.createTextRange();
            range.collapse(true);
            range.moveEnd("character", pos);
            range.moveStart("character", pos);
            range.select()
        }
    }

    function mask(event) {
        let matrix = "+7 (___) ___-__-__",
            i = 0,
            def = matrix.replace(/\D/g, ""),
            val = this.value.replace(/\D/g, "");
        if (def.length >= val.length) val = def;
        this.value = matrix.replace(/./g, function (a) {
            return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : a
        });
        if (event.type == "blur") {
            if (this.value.length == 2) this.value = "";
        } else setCursorPosition(this.value.length, this)
    }

    let inputTel = $('input[type="tel"]');
    inputTel.on("input", mask);
    inputTel.on("focus", mask);
    inputTel.on("blur", mask);

    // Мобильное меню
    $('.b-toggle-menu').click(function () {
        $('body').toggleClass('st_active');
    });

    //МЕНЮ
    $('.js_more_link').click(function () {
        $('.b_filter').toggleClass('hide');
        $('.js_more_link').toggleClass('hide');
        return false;
    });

    $('.js_submenu_one').click(function () {
        $('.b_submenu_one').toggleClass('open');
        $('.t--submenu').toggleClass('open');
        return false;
    });

    $(document).on('click', function (e) {
        if (!$(e.target).closest(".b_submenu_one").length) {
            $('.b_submenu_one').removeClass('open');
            $('.t--submenu').removeClass('open');
        }
        e.stopPropagation();
    });

    document.addEventListener('mouseenter', function (event) {
        if (event.target.classList.contains('nav-item')) {
            let navPills = event.target.querySelector('.nav-pills');
            if (navPills) {
                navPills.classList.add('open');
            }
        }
    }, true); // Используем capture phase

    document.addEventListener('mouseleave', function (event) {
        if (event.target.classList.contains('nav-item')) {
            let navPills = event.target.querySelector('.nav-pills');
            if (navPills) {
                navPills.classList.remove('open');
            }
        }
    }, true); // Используем capture phase

    //Поп-апы
    let app = {
        initTopbox: function ($elements) {
            // iFrames require a data-lightbox-type="iframe" attribute.
            // AJAX requires a data-lightbox-type="ajax" attribute.
            // Inline requires a data-lightbox-type="inline" attribute.
            // Video iFrames require a data-lightbox-type="video-iframe" attribute.
            if ($elements.length) {
                $elements.topbox({
                    keyboardNav: false,
                    afterShowLightbox: function (lightbox) {
                        $('body').on('click', '.js_close_nivo', function () {
                            $('.topbox_close').trigger('click');
                        });
                    }
                });
            }
        },

        openTopbox: function (url) {
            var $link = $('<a href="' + url + '" class="js_lightbox" data-lightbox-type="ajax" aria-haspopup="dialog"></a>');
            this.initTopbox($link);
            $link
                .click()
                .remove();
        }
    };

    app.initTopbox($('.js_lightbox'));


    /*-- Слайдер на главной --*/
    $('.owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 8000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
                arrows: false,
                nav: false
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });
    /*--Галерея фото в карточке товара--*/
    $('.slider-large').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slick-vertical',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    //arrows: true,
                    //prevArrow: '<div class="i--up"></div>',
                    //nextArrow: '<div class="i--down"></div>'
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    //arrows: true,
                    //prevArrow: '<div class="i--up"></div>',
                    //nextArrow: '<div class="i--down"></div>'
                    dots: true
                }
            }

        ]
    });
    $('.slick-vertical').slick({
        asNavFor: '.slider-large',
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 3,
        autoplay: false,
        focusOnSelect: true,
        prevArrow: '<div class="i--up"></div>',
        nextArrow: '<div class="i--down"></div>'

    });

    /* Похожие товары в карточке */
    $('.slick-similar-goods').slick({
        slidesToShow: 5,
        slidesToScroll: 2,
        arrows: true,
        prevArrow: '<div class="i--left"></div>',
        nextArrow: '<div class="i--right"></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 2,
                    arrows: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true,
                    dots: false
                }
            }

        ]
    });

    /* Похожие товары в карточке  у недоступных товаров*/
    $('.slick-relative-goods').slick({
        slidesToShow: 7,
        slidesToScroll: 3,
        arrows: true,
        prevArrow: '<div class="i--left"></div>',
        nextArrow: '<div class="i--right"></div>',
        responsive: [
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3,
                    arrows: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    slidesToShow: 4,
                    slidesToScroll: 2,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    arrows: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true,
                    dots: false
                }
            }

        ]
    });

    /* Главная - популярные товары */
    $('.slick-pop-goods').slick({
        slidesToShow: 5,
        slidesToScroll: 3,
        arrows: true,
        prevArrow: '<div class="i--left"></div>',
        nextArrow: '<div class="i--right"></div>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    arrows: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                    arrows: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false
                }
            }

        ]
    });
    /* Главная - товары со скидкой */
    $('.slick-sale-goods').slick({
        slidesToShow: 5,
        slidesToScroll: 3,
        arrows: true,
        prevArrow: '<div class="i--left"></div>',
        nextArrow: '<div class="i--right"></div>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    arrows: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    arrows: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    arrows: true,
                    dots: false
                }
            }

        ]
    });
    /* Главная - товары со скидкой */
    $('.slick-new-goods').slick({
        slidesToShow: 5,
        slidesToScroll: 3,
        arrows: true,
        prevArrow: '<div class="i--left"></div>',
        nextArrow: '<div class="i--right"></div>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    arrows: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    arrows: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    arrows: true,
                    dots: false
                }
            }

        ]
    });

    /* Простая галерея фото */
    $('.slick-img').slick({
        slidesToShow: 3,
        margin: 10,
        slidesToScroll: 2,
        arrows: true,
        prevArrow: '<div class="i--left"></div>',
        nextArrow: '<div class="i--right"></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    arrows: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false
                }
            }

        ]
    });

    /* Простая галерея фото */
    $('.slick-banners').slick({
        slidesToShow: 2,
        margin: 10,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<div class="i--left"></div>',
        nextArrow: '<div class="i--right"></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    margin: 10,
                    arrows: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    margin: 10,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    margin: 10,
                    arrows: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    margin: 10,
                    arrows: true,
                    dots: false
                }
            }

        ]
    });

    /*--Кнока Наверх--*/
    $(window).scroll(function () {
        if ($(this).scrollTop() != 0) {
            $('.to-top').fadeIn();
        } else {
            $('.to-top').fadeOut();
        }
    });
    $('.to-top').click(function () {
        $('body,html').animate({scrollTop: 0}, 800);
    });

    /*--Фиксированное меню--*/
    $(window).scroll(function () {
        $('.b_search').toggleClass('scroll_menu', $(this).scrollTop() > 150);
    });

    /*--Активная ссылка--*/
    $(function () {
        $('.js_active-link [href]').each(function () {
            if (this.href == window.location.href) {
                $(this).addClass('st_active');
            }
        });
    });

    /*--Подсказка на карте--*/
    $(document).ready(function () {
        $('.pathmap').click(function () {
            inp = $("#suggest").val().trim();
            if (!inp) {
                $('.hint').removeClass('d-none');
            } else {
                $('.hint').addClass('d-none');
            }
        })
    });

    /*--Мобильный поиск--*/
    $(document).ready(function () {
        $('.js-mobile-search').click(function () {
            $('.el_mobile-search').addClass('active');
        })
    });


///////////////////////
// Tab JS
///////////////////////

// Get all tab elements
    var $tabButtons = Array.prototype.slice.call(document.querySelectorAll('.js-tab-title'), 0);
    var $tabContent = Array.prototype.slice.call(document.querySelectorAll('.tab-content'), 0);

    // Check if there are any tabs on the page
    if ($tabButtons.length > 0) {

        // Add a click event on each of them
        $tabButtons.forEach(function ($el) {
            $el.addEventListener('click', function () {

                $tabContent.forEach(function (e) {
                    e.classList.remove("is-active");
                });

                //Remove active class from other tabs
                $tabButtons.forEach(function ($el) {
                    $el.classList.remove("is-active");
                });

                // Get the target from the clicked tab
                var target = $el.dataset.target;
                var $target = document.getElementById(target);

                // Toggle the class on both the tab and the content targetted
                $el.classList.toggle('is-active');
                $target.classList.toggle('is-active');

            });
        });
    }

});

// ФИЛЬТРАЦИЯ
$('.js_hide_filter').click(function () {
    $(this).toggleClass('st_hide');
});

/*--Обратный отсчёт для товара дня--*/
$(document).ready(function () {
    function countdownDay() {
        let now = new Date(),
            hLeft = 23 - now.getHours(),
            mLeft = 59 - now.getMinutes(),
            sLeft = 59 - now.getSeconds(),
            $timeeWrappers = $('.time-wrap');
        if (hLeft < 10) {
            hLeft = "0" + hLeft;
        }
        if (mLeft < 10) {
            mLeft = "0" + mLeft;
        }
        if (sLeft < 10) {
            sLeft = "0" + sLeft;
        }

        document.getElementById("time_daytovar").innerHTML = hLeft + ":" + mLeft + ":" + sLeft;
    }

    if (document.getElementById("time_daytovar") != null) {
        countdownDay();
        setInterval(countdownDay, 1000);
    }

    // enable file uploader plugin https://innostudio.de/fileuploader/documentation/#examples
    $('input.js_load').fileuploader({
        captions: 'ru',
        addMore: true,
        enableApi: true,
        inputNameBrackets: true,
        thumbnails: {
            onItemShow: function (item) {
                // add sorter button to the item html
                item.html.find('.fileuploader-action-remove').before('<button type="button" class="fileuploader-action fileuploader-action-sort" title="Sort"><i class="fileuploader-icon-sort"></i></button><div class="js_thistext fileuploader-action"></div>');
                item.html.find('.js_thistext').append(item.html.closest('label').find('.image-sortable>.image:first-child'));
            }
        },
        sorter: {
            selectorExclude: null,
            placeholder: null,
            scrollContainer: window,
            onSort: function (list, listEl, parentEl, newInputEl, inputEl) {
            }
        }
    });
    $('input.js_load_one').fileuploader({
        captions: 'ru',
        changeInput: true,
        limit: 1,
        thumbnails: {}
    });
});

/*--Перевод файла SVG в код--*/
$(document).ready(function () {
    $('img.js-img-svg').each(function () {
        var $img = $(this);
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
        $.get(imgURL, function (data) {
            var $svg = $(data).find('svg');
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }
            $svg = $svg.removeAttr('xmlns:a');
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }
            $img.replaceWith($svg);
        }, 'xml');
    });

    $("input[maxLength]").on("keyup",function() {
        var maxLength = $(this).attr("maxlength");
        if(maxLength == $(this).val().length) {
            alert("Внимание! Строка до " + maxLength +" символов")
        }
    });

    //Универсальный аккордеон
    $('.js-akk-trigger').on('click', function() {
        var id = $(this).attr('data-akk'),
        content = $('.js-akk-content[data-akk="'+ id +'"]');

        $(this).toggleClass('true');
        content.toggleClass('true');
    });

    //tippy
    tippy('[data-tippy-content]', {
        theme: 'light',
        placement: 'top',
        allowHTML: true,
    });

    tippy('[data-tippy-top]', {
        theme: 'light',
        placement: 'top',
        allowHTML: true,
    });

    tippy('.js-goods', {
        onShow(instance) {
            let $this = instance['reference'];
            $($this).addClass('st_active');
            $.ajax({
                type: 'GET',
                url: '/good/information/' + $this.dataset.goods,
                cache: false,
                success: function(data) {
                    instance.setContent(data);
                }
            })
        },
        onHidden(instance) {
            instance.setContent('Загрузка...');
            // Unset these properties so new network requests can be initiated
            instance._src = null;
            instance._error = null;
        },
        placement: 'top',
        theme: 'light',
        allowHTML: true,
        interactive: true,
        interactiveBorder: 50,
        content: 'Загрузка...',
    });


    //Слайдер для редактора
    var tileSlider = $('.sl-init');
        tileSlider.each(function(){
            let name = $(this).data('name'),
                slide = $(this).data('slide'),
                autoH = $(this).data('autoheight'),
                sName = '',
                slidename = $(this).data('slidename'),
                sm = 0,
                md = 0,
                lg = 0,
                xl = 0;

            console.log(slide);

            switch(slide){
                case 1:
                    sm = 1;
                    md = 1;
                    lg = 1;
                    xl = 1;
                    break;
                    sm = 2.5;
                    md = 2.5;
                    lg = 2.5;
                    xl = 2.5;
                    break;
                case 2:
                    sm = 1;
                    md = 1;
                    lg = 2;
                    xl = 2;
                    break;
                case 3:
                    sm = 1;
                    md = 2;
                    lg = 2;
                    xl = 3;
                    break;
                case 4:
                    sm = 1;
                    md = 2;
                    lg = 2;
                    xl = 3;
                    break;
                case 5:
                    sm = 2;
                    md = 3;
                    lg = 4;
                    xl = 5;
                    break;
                case 6:
                    sm = 2;
                    md = 3;
                    lg = 4;
                    xl = 5;
                    break;
            }


            $(this).slick({
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: slide,
                slidesToScroll: 1,
                responsive: [
                    {
                    breakpoint: 1200,
                        settings: {
                            slidesToShow: slide
                        }
                    },
                    {
                    breakpoint: 992,
                        settings: {
                            slidesToShow: xl
                        }
                    },
                    {
                    breakpoint: 768,
                        settings: {
                            slidesToShow: lg
                        }
                    },
                    {
                    breakpoint: 576,
                        settings: {
                            slidesToShow: md
                        }
                    },
                    {
                    breakpoint: 480,
                        settings: {
                            slidesToShow: sm
                        }
                    },
                ]
            });





            //         var mySwiper = new Swiper(this, {
    //             modules: [Navigation, Scrollbar, Mousewheel, Pagination],
    //             slidesPerView: sm,
    //             spaceBetween: 20,
    //             autoHeight: autoH,
    //             pagination: {
    //                 el: '.' + name + '-pagination',
    //                 bulletClass: 'bullet',
    //                 bulletActiveClass: 'active',
    //                 clickable: 'true',
    //             },
    //             navigation: {
    //                 nextEl: '.' + name + '-next',
    //                 prevEl: '.' + name + '-prev',
    //             },
    //             scrollbar: {
    //                 el: '.' + name + '-scrollbar',
    //                 hide: false,
    //               },
    //             breakpoints: {
    //                 576: {
    //                     slidesPerView: md,
    //                 },
    //                 768: {
    //                     slidesPerView: lg,
    //                 },
    //                 992: {
    //                     slidesPerView: xl,
    //                 },
    //                 1200: {
    //                     slidesPerView: slide,
    //                 }
    //             },
    //         });
        });
});

/* Новогоднее оформление */
// function garland() {
//     let nums = document.getElementById('nums_1').innerHTML;
//
//     if (nums == 1) {
//       document.getElementById('garland').className = 'garland_1';
//       document.getElementById('nums_1').innerHTML = '2'
//     }
//     if (nums == 2) {
//       document.getElementById('garland').className = 'garland_2';
//       document.getElementById('nums_1').innerHTML = '3'
//     }
//     if (nums == 3) {
//       document.getElementById('garland').className = 'garland_3';
//       document.getElementById('nums_1').innerHTML = '4'
//     }
//     if (nums == 4) {
//       document.getElementById('garland').className = 'garland_4';
//       document.getElementById('nums_1').innerHTML = '1'
//     }
//   }
//
//   setInterval(function() {
//     garland(); }, 800);
/* END Новогоднее оформление */

require("packs/owl.carousel");
require("packs/slick");
